export const technicalSkillsConfig = [
  "JavaScript",
  "TypeScript",
  "React",
  "Next.js",
  "Redux",
  "NPM",
  "Yarn",
  "Git(+Github)",
  "Firebase",
];
