import NftCoverImage from "../assets/project-images/NFT/nft-cover.png";
import NftFrontImage from "../assets/project-images/NFT/nft-front.png";
import NftFirstImage from "../assets/project-images/NFT/nft-desktop.png";
import NftSecondImage from "../assets/project-images/NFT/nft-phones-1.jpg";
import NftThirdImage from "../assets/project-images/NFT/nft-phones-2.jpg";

import TreactCoverImage from "../assets/project-images/treact/treact-cover.jpg";
import TreactFrontImage from "../assets/project-images/treact/treact-front.png";
import TreactFirstImage from "../assets/project-images/treact/treact-desktop.png";
import TreactSecondImage from "../assets/project-images/treact/treact-phones-1.jpg";
import TreactThirdImage from "../assets/project-images/treact/treact-phones-2.png";

import EcommerceCover from "../assets/project-images/ecommerce/ecommerce-cover.png";
import EcommerceFrontImage from "../assets/project-images/ecommerce/ecommerce-front.png";
import EcommerceFirstImage from "../assets/project-images/ecommerce/ecommerce-desktop.png";
import EcommerceSecondImage from "../assets/project-images/ecommerce/ecommerce-phones-1.jpg";
import EcommerceThirdImage from "../assets/project-images/ecommerce/ecommerce-phones-2.jpg";

const projectsConfig = [
  {
    title: "NFT Marketplace",
    type: "Web App",
    description: 
   " Discover and own digital masterpieces on Ultraverse. Buy, sell, and trade NFTs effortlessly. Your gateway to the future of digital collectibles.",
    liveLink: "https://zubayr-internship.vercel.app/",
    githubLink: "https://github.com/zubayr-parker/zubayr-internship",
    coverImage: NftCoverImage,
    topImage: NftFrontImage,
    firstImage: NftFirstImage,
    secondImage: NftSecondImage,
    thirdImage: NftThirdImage,
  },
  {
    title: "Treact",
    type: "Web App",
    description:
    "Elevate your online presence with Treact. Explore our collection of meticulously crafted templates designed for businesses, portfolios, and more. Simplify your web design process, save time, and create a stunning website today. Your path to a polished online image begins here.",
    liveLink: "https://zubayr-parker.github.io/firstlibrary/",
    githubLink: "https://github.com/zubayr-parker/firstlibrary",
    coverImage: TreactCoverImage,
    topImage: TreactFrontImage,
    firstImage: TreactFirstImage,
    secondImage: TreactSecondImage,
    thirdImage: TreactThirdImage,
  },
  {
    title: "React Ecommerce",
    type: "Web App",
    description:
    
    "Unleash Your Love for Reading. Shop, discover, and immerse yourself in a world of books. Browse an extensive library, find your next adventure, and experience the joy of reading like never before. Your book-shopping journey begins here.",
    liveLink: "https://ecommerce-app-chi-nine.vercel.app/",
    githubLink: "https://github.com/zubayr-parker/ecommerce-app",
    coverImage: EcommerceCover,
    topImage: EcommerceFrontImage,
    firstImage: EcommerceFirstImage,
    secondImage: EcommerceSecondImage,
    thirdImage: EcommerceThirdImage,
  },
];

export default projectsConfig;
