import React from "react";
import { MdOutlineArrowUpward } from "react-icons/md";
import FooterLogo from "../assets/footer-logo.png";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <a href="#" className="footer__logo">
        <img class="white-filter" src={FooterLogo} alt="" />
        <span className="footer__scroll-top">
          Top <MdOutlineArrowUpward />
        </span>
      </a>
      <div className="footer__link--wrapper">
        <div>
          <a href="https://github.com/zubayr-parker/" target="_blank">Github</a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/zubayr-parker/" target="_blank">LinkedIn</a>
        </div>
        <div>
          <a href="mailto:zubayr_parker@outlook.com" target="_blank">Email</a>
        </div>
        <div>
          <a href="Zubayr-Resume.pdf" target="_blank">Resume</a> 
        </div>
      </div>
      <div>Copyright &copy; 2024 Zubayr Parker</div>
    </div>
  );
};

export default Footer;
