export const experienceConfig = [
  {
    name: "Chaos 1",
    title: "Software Engineering (Frontend) Intern",
    description: [
      "Spearheaded the design and development of compelling websites, employing JavaScript, React, and TypeScript; achieved a 30% increase in website traffic",
      "Operated on complex front-end functionalities, integrating data management to deliver a seamless user experience",
      " Collaborated with cross-functional teams of designers and product managers to elicit project requirements, contributing innovative solutions that resulted in a 20% increase in customer engagement",
    ],
    date: "Oct 2023 - Feb 2024",
  },
  {
    name: "Frontend Simplified",
    title: "Software Engineering (Frontend) Intern",
    description: [
      "Revamped a static web application using HTML, CSS, JavaScript, and React, introducing interactive elements such as animated effects, smooth transitions, and carousels; enhanced user experience",
      "Led the implementation of Axios to facilitate API request execution, data retrieval, and display from a cloud server", 
      "Enhanced user experience through the implementation of skeleton loading states, pagination, and routing, leading to a significant 40% increase in page loading speed and a 25% decrease in bounce rate",
      "Orchestrated the successful integration of Git version control and the GitHub interface, empowering our virtual team to collaborate seamlessly; reduced code conflicts by 50% and facilitated faster feature delivery by 15%"
    ],
    date: "Jul 2023 - Dec 2023",
  },
];
